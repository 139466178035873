import { MainContainer, StyledSkeleton } from './styled'

const LoadingState = () => {
  return (
    <MainContainer>
      <StyledSkeleton variant="rounded" />
    </MainContainer>
  )
}

export default LoadingState
