import { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { useRouter } from 'next/router'
import { Theme, useMediaQuery } from '@mui/material'

import ServicesList from './ServicesList'
import ServiceDisplay from './ServiceDisplay'
import NoServicesAvailable from './NoServicesAvailable'

import { BackgroundGradient, BackgroundGradientDesktop } from './ServiceDisplay/styled'
import { Container, VideoBox, VideoPlayer, VideoBackgroundTransition } from './styled'

import { useServices } from 'hooks/services/useServices'
import { useDefaultBackground } from 'hooks/services/useDefaultBackground'
import { useAutoRotateTimer } from 'hooks/services/useAutoRotateTimer'
import { useInView } from 'framer-motion'

const ServicesPage = () => {
  const downSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'))
  const videoBreakpoint = useMediaQuery<Theme>((theme) => theme.breakpoints.up(1028))
  const videoRef = useRef<HTMLVideoElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const inView = useInView(containerRef, { amount: 0.5 })

  const {
    defaultBackgroundImage,
    defaultMobileBackgroundImage,
    defaultBackgroundVideo,
    isLoadingBackground,
  } = useDefaultBackground()
  const { services, isLoadingServices } = useServices()

  const { autoRotateTimer, isLoadingTimer } = useAutoRotateTimer()
  const router = useRouter()
  const { s: serviceInQuery } = router.query
  const hasSetFromQuery = useRef(false)

  const [currentIndexes, setCurrentIndexes] = useState<{
    serviceIndex: number
    subServiceIndex: number
  }>({ serviceIndex: 0, subServiceIndex: 0 })
  const [pressed, setPressed] = useState(false)
  const [autoTimer, setAutoTimer] = useState<number>(autoRotateTimer)

  /**
   * Better approach to check client side without triggering the Hydration error.
   * @see https://nextjs.org/docs/messages/react-hydration-error
   */
  const [isClient, setIsClient] = useState(false)

  const previousServiceId = useRef(0)

  const compareSubServiceNameWithQuery = useCallback(
    (subServiceName = '') => {
      const query = serviceInQuery as string // the query should always be a string
      return subServiceName.localeCompare(query, undefined, { sensitivity: 'accent' }) === 0
    },
    [serviceInQuery],
  )

  const currentSubService = useMemo(() => {
    if (services?.length) {
      if (serviceInQuery && !hasSetFromQuery.current) {
        for (let serviceIndex = 0; serviceIndex < services.length; serviceIndex++) {
          const subServices = services[serviceIndex].subServices
          for (let subServiceIndex = 0; subServiceIndex < subServices.length; subServiceIndex++) {
            if (compareSubServiceNameWithQuery(subServices[subServiceIndex]?.name)) {
              previousServiceId.current = services[currentIndexes.serviceIndex].id || 0

              setCurrentIndexes({
                serviceIndex,
                subServiceIndex,
              })
              hasSetFromQuery.current = true
              return services[serviceIndex].subServices[subServiceIndex]
            }
          }
        }
      }

      const currentService = services[currentIndexes.serviceIndex]

      if (currentService?.subServices?.length) {
        const subService = currentService.subServices[currentIndexes.subServiceIndex]
        if (
          isClient &&
          !compareSubServiceNameWithQuery(subService.name) &&
          router.asPath.includes('service')
        ) {
          router.push({ query: { s: subService.name?.toLowerCase() } }, undefined, {
            shallow: true,
          })
        }
        return subService
      }
    }

    return null
  }, [services, serviceInQuery, currentIndexes, router, isClient, compareSubServiceNameWithQuery])

  const serviceTitle = services[currentIndexes.serviceIndex].name

  const isLoading = isLoadingServices || isLoadingBackground || isLoadingTimer

  const prevSubService = useCallback(() => {
    setCurrentIndexes((prevState) => {
      const { serviceIndex, subServiceIndex } = prevState
      const currentService = services[serviceIndex]

      previousServiceId.current = currentService.id || 0

      if (subServiceIndex > 0) {
        return {
          serviceIndex,
          subServiceIndex: subServiceIndex - 1,
        }
      }

      const previousServiceIndex = (serviceIndex - 1 + services.length) % services.length
      const previousSubServiceIndex = services[previousServiceIndex].subServices.length - 1

      return {
        serviceIndex: previousServiceIndex,
        subServiceIndex: previousSubServiceIndex,
      }
    })
  }, [services])

  const nextSubService = useCallback(() => {
    setCurrentIndexes((prevState) => {
      const { serviceIndex, subServiceIndex } = prevState
      const currentService = services[serviceIndex]

      previousServiceId.current = currentService.id || 0

      if (subServiceIndex < currentService.subServices.length - 1) {
        return {
          serviceIndex,
          subServiceIndex: subServiceIndex + 1,
        }
      }

      const nextServiceIndex = (serviceIndex + 1) % services.length

      return {
        serviceIndex: nextServiceIndex,
        subServiceIndex: 0,
      }
    })
  }, [services])

  const setSubService = useCallback(
    (id: number) => {
      setPressed((prevState) => !prevState)
      for (let serviceIndex = 0; serviceIndex < services.length; serviceIndex++) {
        const subServices = services[serviceIndex].subServices
        for (let subServiceIndex = 0; subServiceIndex < subServices.length; subServiceIndex++) {
          if (subServices[subServiceIndex].id === id) {
            previousServiceId.current = services[currentIndexes.serviceIndex].id || 0
            setCurrentIndexes({
              serviceIndex,
              subServiceIndex,
            })
            return
          }
        }
      }
    },
    [services, currentIndexes.serviceIndex],
  )

  useEffect(() => {
    if (!!pressed) {
      setTimeout(() => {
        setAutoTimer(autoRotateTimer)
      }, 1000)
    }
    if (autoRotateTimer && autoTimer <= 0) {
      setAutoTimer(autoRotateTimer)
      nextSubService()
    }
    if (!pressed && autoTimer > 0) {
      if (inView) {
        handleTimer(autoTimer)
      }
    }
  }, [autoTimer, pressed, autoRotateTimer, isLoadingTimer, nextSubService, inView])

  const handleTimer = (time: number) => {
    setTimeout(() => {
      setAutoTimer(time - 1)
    }, 1000)
  }

  useEffect(() => {
    if (currentSubService?.serviceId !== previousServiceId.current) {
      videoRef.current?.load()
    }
  }, [currentSubService?.backgroundVideo, currentSubService?.serviceId, previousServiceId])

  useEffect(() => {
    setIsClient(true)
  }, [])

  const hasVideo =
    currentSubService?.backgroundVideo ||
    (!currentSubService?.backgroundImage && defaultBackgroundVideo)

  const getBackgroundImage = useCallback(() => {
    if (downSm) {
      if (currentSubService?.mobileBackgroundImage) {
        return currentSubService?.mobileBackgroundImage.fullSize
      }
      return defaultMobileBackgroundImage
    }

    return currentSubService?.backgroundImage || defaultBackgroundImage
  }, [currentSubService, downSm, defaultBackgroundImage, defaultMobileBackgroundImage])

  if (!isLoadingServices && !services?.length) return <NoServicesAvailable />

  const serviceDisplayProps = hasVideo ? { hasVideo } : { backgroundImage: getBackgroundImage() }

  return (
    <Container ref={containerRef}>
      {isClient && hasVideo && (
        <VideoBox>
          <VideoBackgroundTransition
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 2 }}
          >
            {videoBreakpoint || <BackgroundGradient />}
            <VideoPlayer ref={videoRef} autoPlay={true} muted loop playsInline id="myVideo">
              <source
                src={
                  currentSubService?.backgroundVideo
                    ? currentSubService?.backgroundVideo
                    : defaultBackgroundVideo
                }
                type="video/mp4"
              />
            </VideoPlayer>
            {videoBreakpoint && <BackgroundGradientDesktop />}
          </VideoBackgroundTransition>
        </VideoBox>
      )}
      <ServicesList
        isLoading={isLoading}
        services={services}
        currentSubServiceId={currentSubService?.id}
        setSubService={setSubService}
        timer={autoTimer}
      />
      <ServiceDisplay
        {...serviceDisplayProps}
        prevSubService={prevSubService}
        nextSubService={nextSubService}
        isLoading={isLoading}
        activeService={currentSubService}
        serviceTitle={serviceTitle}
      />
    </Container>
  )
}

export default ServicesPage
