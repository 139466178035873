import { MainContainer, StyledSkeleton } from './styled'

const LoadingState = () => {
  return (
    <MainContainer>
      <StyledSkeleton variant="rounded" sx={{ width: '25%', height: '21px' }} />
      <StyledSkeleton variant="rounded" sx={{ width: '50%', height: '58px' }} />
      <StyledSkeleton variant="rounded" sx={{ width: '100%', height: '80px', marginTop: 4 }} />
      <StyledSkeleton variant="rounded" sx={{ width: '33%', height: '40px' }} />
    </MainContainer>
  )
}

export default LoadingState
