import ServicesApi from 'api/ServicesApi'
import { useQuery } from 'react-query'
import { QUERY_KEYS } from './constants'

export function useAutoRotateTimer() {
  const { data, isLoading, ...rest } = useQuery({
    queryKey: QUERY_KEYS.timer,
    queryFn: () => ServicesApi.getAutoRotateTimer(),
  })

  const lastTimer = data?.results[data?.results?.length - 1]

  return {
    autoRotateTimer: lastTimer?.time || 20,
    isLoadingTimer: isLoading,
    ...rest,
  }
}
