import { ControlsContainer, StyledIconButton } from './styled'

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

import { IControlsProps } from './types'

export default function Controls({
  leftDisabled,
  rightDisabled,
  prevSlide,
  nextSlide,
  containerProps = {},
}: IControlsProps) {
  return (
    <ControlsContainer {...containerProps}>
      <StyledIconButton
        aria-label="previous-slide"
        size="large"
        onClick={prevSlide}
        disabled={leftDisabled}
        title="Previous Slide"
      >
        <KeyboardArrowLeftIcon />
      </StyledIconButton>
      <StyledIconButton
        aria-label="next-slide"
        size="large"
        onClick={nextSlide}
        disabled={rightDisabled}
        title="Next Slide"
      >
        <KeyboardArrowRightIcon />
      </StyledIconButton>
    </ControlsContainer>
  )
}
