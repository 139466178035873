import { IServiceResult } from 'components/Services/ServicesList/types'
import {
  IAutoRotateTimerResult,
  IDefaultBackgroundResult,
  IUseSubservice,
} from 'components/Services/types'
import axios, { apiBaseURL } from './axios'

const projectsBaseURL = `${apiBaseURL}`

export default class ServicesApi {
  static list(): Promise<IServiceResult> {
    return axios.get(`${projectsBaseURL}/services`)
  }

  static getService(slug: string): Promise<IServiceResult> {
    return axios.get(`${projectsBaseURL}/services?slug=${slug}`)
  }
  static getSubService(id: number): Promise<IUseSubservice> {
    return axios.get(`${projectsBaseURL}/subservices/${id}`)
  }

  static getDefaultBackground(): Promise<IDefaultBackgroundResult> {
    return axios.get(`${projectsBaseURL}/default-services-background`)
  }

  static getAutoRotateTimer(): Promise<IAutoRotateTimerResult> {
    return axios.get(`${projectsBaseURL}/auto-rotate-services-timer`)
  }
}
