import { Box, IconButton, styled } from '@mui/material'

export const ControlsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
}))

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.surface['800'],
  color: theme.palette.surface['100'],
  padding: '9px',
  '&.Mui-disabled': {
    backgroundColor: theme.palette.surface['900'],
    color: theme.palette.surface['800'],
  },
  '&:hover': {
    backgroundColor: theme.palette.surface['800'],
    filter: 'brightness(1.1)',
  },
}))
