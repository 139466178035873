import { styled, Typography, TypographyProps, Box } from '@mui/material'

export const NoServicesWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  minHeight: '100vh',
  backgroundColor: theme.palette.surface['800'],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const NoServices = styled((props) => <Typography component="h1" {...props }/>)<TypographyProps>(({ theme }) => ({
  ...theme.typography.h3,
  color: theme.palette.surface['50'],
}))
