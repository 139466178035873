import { FC } from 'react'
import { NoServicesWrapper, NoServices } from './styled'
import { INoServicesAvailable } from './types'

const NoServicesAvailable: FC<INoServicesAvailable> = ({}) => {
  return (
    <NoServicesWrapper>
      <NoServices>No Services Available</NoServices>
    </NoServicesWrapper>
  )
}
export default NoServicesAvailable
