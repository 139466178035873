import { Box, Skeleton, styled } from '@mui/material'

export const MainContainer = styled(Box)(({ theme }) => ({
  width: '250px',
  [theme.breakpoints.down(1028)]: {
    width: '100%',
  },
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}))

export const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  backgroundColor: theme.palette.surface[900],
  width: '100%',
  height: '100%',
  [theme.breakpoints.down(1028)]: {
    height: '200px',
  },
}))
