import { styled, Typography, CircularProgress, Box } from '@mui/material'

export const ServicesWrapper = styled(Box)(({ theme }) => ({
  height: '100%',
  minWidth: '299px',
  backgroundColor: theme.palette.surface['A900'],
  position: 'relative',
  zIndex: 2,
  padding: theme.spacing(0.5),
  border: `${theme.spacing(0.25)} solid ${theme.palette.surface['800']}`,
  borderRadius: theme.spacing(2),
  [theme.breakpoints.down(1028)]: {
    width: 'calc(100% - 80px)',
  },
  [theme.breakpoints.down('sm')]: {
    width: '90%',
  },
}))

export const ServiceContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3.5),
  [theme.breakpoints.down(1028)]: {
    flexDirection: 'row',
    overflowX: 'auto',
    width: '100%',
    paddingBottom: theme.spacing(2.25),
    gap: theme.spacing(5.25),
  },
  [theme.breakpoints.up('lg')]: {
    overflowY: 'auto',
  },
}))

export const LoadingContainer = styled(Box)(({ theme }) => ({
  width: theme.spacing(35.5),
  backgroundColor: theme.palette.surface['A900'],
  border: `${theme.spacing(0.25)} solid ${theme.palette.surface['800']}`,
  borderRadius: theme.spacing(2),
  boxShadow: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1.75)} rgba(0, 0, 0, 0.25)`,
  marginLeft: theme.spacing(1.25),
  marginRight: theme.spacing(1.25),
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4.5),
  [theme.breakpoints.up('lg')]: {
    minHeight: theme.spacing(75),
  },
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down(1028)]: {
    width: '94%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '90%',
  },
}))

export const ServiceSection = styled(Box)(() => ({
  whiteSpace: 'nowrap',
}))

export const ServiceCategoryName = styled(Typography)(({ theme }) => ({
  ...theme.typography.caption,
  color: theme.palette.surface['200'],
  marginBottom: theme.spacing(2),
}))

export const SubServiceRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: theme.spacing(1),
  height: theme.spacing(3),
  marginBottom: theme.spacing(1.5),
  '&:hover': {
    cursor: 'pointer',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'row',
    overflow: 'auto',
    width: '100%',
    height: 'auto',
    paddingRight: theme.spacing(2),
  },
}))

export const SubServiceImage = styled('img')(({ theme }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
}))

export const SubServiceName = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.surface['400'],
}))

export const ActiveSubServiceName = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.primary['500'],
  fontWeight: 700,
}))

export const CircularLoading = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.surface['600'],
}))
