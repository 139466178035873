import { styled, Box, Container as MUIContainer } from '@mui/material'
import { motion } from 'framer-motion'

export const Container = styled(MUIContainer)(({ theme }) => ({
  backgroundColor: '#000',
  position: 'relative',
  display: 'flex',
  justifyContent: 'flex-start',
  padding: `${theme.spacing(3.75)} ${theme.spacing(5)}`,
  gap: theme.spacing(11.75),
  minHeight: theme.spacing(104),
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '100%65%',
  maxWidth: '1440px',
  [theme.breakpoints.down(1028)]: {
    padding: `${theme.spacing(3.75)} 0px`,
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(3),
    background: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '0px',
    paddingTop: theme.spacing(1.75),
  },
  [theme.breakpoints.up('lg')]: {
    maxHeight: 'calc(100vh - 80px)',
    minHeight: 'unset',
    height: theme.spacing(104),
    overflow: 'hidden',
  },
}))

export const VideoPlayer = styled('video')(() => ({
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  objectFit: 'cover',
  pointerEvents: 'none',
}))

export const VideoBox = styled(Box)(({ theme }) => ({
  order: 3,
  marginTop: 100,
  [theme.breakpoints.up(1028)]: {
    marginTop: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
}))

export const VideoBackgroundTransition = styled(motion.div)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  height: 'initial',
  marginTop: '-100px',
  [theme.breakpoints.up(1028)]: {
    position: 'absolute',
    height: '80%',
    width: 'auto',
    right: 0,
    bottom: 0,
    objectFit: 'cover',
  },
}))
