import React, { FC, useEffect, useRef } from 'react'
import { IServicesList, IService, ISubService } from './types'
import LoadingState from './LoadingState'
import {
  ServicesWrapper,
  ServiceSection,
  ServiceCategoryName,
  SubServiceRow,
  SubServiceImage,
  SubServiceName,
  LoadingContainer,
  ActiveSubServiceName,
  ServiceContainer,
} from './styled'

const ServicesList: FC<IServicesList> = ({
  services,
  isLoading,
  currentSubServiceId,
  setSubService,
}) => {
  const servicesContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const scrollToSubService = () => {
      const servicesContainer = servicesContainerRef.current
      if (servicesContainer) {
        const activeSubServiceElement = servicesContainer.querySelector(
          `[data-id="${currentSubServiceId}"]`,
        ) as HTMLElement

        if (activeSubServiceElement) {
          servicesContainer.scrollTo({
            left: activeSubServiceElement.offsetLeft - servicesContainer.offsetLeft - 20,
            behavior: 'smooth',
          })
        }
      }
    }

    scrollToSubService()
  }, [currentSubServiceId])

  if (isLoading)
    return (
      <LoadingContainer>
        <LoadingState />
      </LoadingContainer>
    )

  return (
    <ServicesWrapper>
      <ServiceContainer ref={servicesContainerRef}>
        {services?.map((service: IService) => (
          <ServiceSection key={service.id}>
            <ServiceCategoryName>{service.name}</ServiceCategoryName>
            {service.subServices
              .filter((subService: ISubService) => subService.activeFlag)
              .map((subService: ISubService) => (
                <SubServiceRow
                  key={subService.id}
                  onClick={() => setSubService(subService.id)}
                  data-id={subService.id}
                >
                  <SubServiceImage
                    alt={subService.iconAltText || ''}
                    src={subService.svgIcon ?? ''}
                    sx={{
                      filter:
                        currentSubServiceId === subService.id
                          ? 'invert(45%) sepia(53%) saturate(2655%) hue-rotate(166deg) brightness(93%) contrast(101%)'
                          : 'invert(44%) sepia(28%) saturate(240%) hue-rotate(166deg) brightness(91%) contrast(88%)',
                    }}
                  />{' '}
                  {currentSubServiceId === subService.id ? (
                    <ActiveSubServiceName>{subService.name}</ActiveSubServiceName>
                  ) : (
                    <SubServiceName>{subService.name}</SubServiceName>
                  )}
                </SubServiceRow>
              ))}
          </ServiceSection>
        ))}
      </ServiceContainer>
    </ServicesWrapper>
  )
}
export default ServicesList
