import ServicesApi from 'api/ServicesApi'
import { IUseServices } from 'components/Services/types'

import { useQuery } from 'react-query'
import { QUERY_KEYS } from './constants'

export function useServices(): IUseServices {
  const { data, isLoading, ...rest } = useQuery({
    queryKey: QUERY_KEYS.services,
    queryFn: () => ServicesApi.list(),
  })

  return {
    services: data?.results || [],
    isLoadingServices: isLoading,
    ...rest,
  }
}
