import { styled, Typography, Box } from '@mui/material'
import { motion } from 'framer-motion'

const shadow =
  'linear-gradient(200deg, #000000 1.95%, rgba(0, 0, 0, 0) 10.88%), linear-gradient(180deg, #000000 5%, rgba(0, 0, 0, 0) 10%), linear-gradient(180deg, #000000 10%, rgba(0, 0, 0, 0) 25.31%), linear-gradient(180deg, rgba(0, 0, 0, 0) 91.25%, #000000 100%)'

const upMdShadow =
  'linear-gradient(to right, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%), linear-gradient(to top, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 21%),linear-gradient(rgb(0, 0, 0) 4%, rgba(0, 0, 0, 0.667) 11.96%, rgba(0, 0, 0, 0) 37%);'

export const ServicesWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  paddingTop: theme.spacing(3),
  width: '100%',
  zIndex: 10,
  [theme.breakpoints.down(1028)]: {
    padding: theme.spacing(0, 5, 6, 5),
  },
  [theme.breakpoints.down('lg')]: {
    padding: `0px ${theme.spacing(3)}`,
  },
}))

export const LoadingWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  backgroundImage: 'none',
}))

export const OurServices = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle1,
  color: theme.palette.surface['50'],
  margin: 0,
  marginBottom: theme.spacing(-1),
  marginLeft: theme.spacing(0.5),
  [theme.breakpoints.down('md')]: {
    ...theme.typography.subtitle2,
    marginBottom: theme.spacing(0.5),
  },
})) as typeof Typography

export const ServiceTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h3,
  maxInlineSize: 325,
  wordBreak: 'break-word',
  [theme.breakpoints.up(375)]: {
    maxInlineSize: 350,
  },
  [theme.breakpoints.up('sm')]: {
    ...theme.typography.h2,
    maxInlineSize: 1200,
  },
  [theme.breakpoints.up('lg')]: {
    ...theme.typography.titleBold,
  },
  [theme.breakpoints.up('xl')]: {
    ...theme.typography.display,
  },
}))

export const ServiceDescription = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.surface['100'],
  marginTop: theme.spacing(4),
  maxWidth: '100ch',
  [theme.breakpoints.down('md')]: {
    fontWeight: 400,
  },
}))

export const ButtonsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  marginTop: theme.spacing(3.5),
  gap: theme.spacing(2),
  height: '42px',
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(2),
  },
}))

export const ServicesButtonsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  marginTop: theme.spacing(2),
  gap: theme.spacing(2),
}))

export const BackgroundTransition = styled(motion.div)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  height: theme.spacing(56.25),
  marginTop: '-100px',
  [theme.breakpoints.up(1028)]: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    marginTop: '-80px',
    left: 0,
    top: 80,
  },
}))

export const BackgroundGradient = styled(Box)(({ theme }) => ({
  position: 'absolute',
  height: 'calc(100% + 10px)',
  width: 'calc(100% + 5px)',
  right: 0,
  bottom: -5,
  zIndex: 1,
  background: shadow,
  [theme.breakpoints.up(1028)]: {
    background: 'unset',
  },
}))

export const BackgroundGradientDesktop = styled(Box)(() => ({
  position: 'absolute',
  height: 'calc(100% + 10px)',
  width: 'calc(100% + 5px)',
  right: 0,
  bottom: -5,
  background: upMdShadow,
  zIndex: 1,
}))

