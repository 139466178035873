import ServicesApi from 'api/ServicesApi'
import { useQuery } from 'react-query'
import { QUERY_KEYS } from './constants'

export function useDefaultBackground() {
  const { data, isLoading, ...rest } = useQuery({
    queryKey: QUERY_KEYS.defaultBackground,
    queryFn: () => ServicesApi.getDefaultBackground(),
  })

  return {
    defaultBackgroundImage: data?.results[0]?.image,
    defaultMobileBackgroundImage: data?.results[0]?.mobileImage,
    defaultBackgroundVideo: data?.results[0]?.video,
    isLoadingBackground: isLoading,
    ...rest,
  }
}
