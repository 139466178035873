import { Box, Skeleton, styled } from '@mui/material'
export const MainContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  [theme.breakpoints.down(1028)]: {
    width: '94%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '90%',
  },
}))

export const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  backgroundColor: theme.palette.surface[900],
  width: '100%',
}))
